<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="flex-1 flex-column justify-center">
			<div><img :src="require('@/assets/image/web/ico_logo_large.svg')" /></div>
			<div class="mt-20 mafia">M.A.F.I.A</div>
			<div class="mt-20">문화예술 전용 커뮤니티 마피아 프로젝트</div>
		</div>

		<div class="intro_btn_wrap pa-30">
			<button class="btn btn-blue radius-20" @click="$emit('to', { name: 'login'})">로그인</button>
			<button class=" mt-20 color-white" @click="$emit('to', { name: 'agree'})">회원가입</button>
			<p class="copyright mt-20 color-gray">Copyright © MAFI Inc. </p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia003'
	,data: function(){
		return {
			program: {
				name: '로그인'
				, not_header: true
				, not_footer: true
				, type: 'auth'
			}
		}
	}
	,methods: {

	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>

<style>
	.container-auth{
		width: 50rem;
	}

	.intro_btn_wrap {
		position: absolute; bottom: 0; left: 0;
		width: 100%;
	}
</style>